import React, { useEffect, useState } from "react";
import { getData } from "../api";
import Sort from "../assets/svg/Sort";

const DailyGamers = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    GetData();
  }, []);
  const GetData = async () => {
    let res = await getData(
      "https://data.mongodb-api.com/app/data-vadbg/endpoint/readscores"
    );
    if (res.success) {
      res.data.forEach((element) => {
        const rounds = [];
        for (let [key, value] of Object.entries(element)) {
          if (key.includes("Round")) {
            rounds.push(JSON.parse(String(value)));
          }
        }
        element["rounds"] = rounds;
      });
      setData(res.data.toReversed());
    }
  };
  const sortByDate = () => {
    setData(data.toReversed());
  };
  const displayRounds = (rounds) => {
    return rounds.map((item, index) => (
      <div className="grid grid-cols-5 gap-4 text-left" key={`round-${index}`}>
        <div className="text-white col-span-5 md:col-span-1 pt-2">
          Round {index}
        </div>
        <div className="text-white col-span-5 md:col-span-1 grid grid-cols-3 md:block">
          <div className="text-white font-bold md:hidden col-span-1">
            Red Team
          </div>
          <div className="col-span-2 md:text-left text-right">
            <b>Name:</b> {item.Teams[0].Members[0].Name} <br />
            <b>Kills: </b>
            {item.Teams[0].Members[0].Kills}
          </div>
        </div>
        <div className="text-white col-span-5 md:col-span-1 grid grid-cols-3 md:block">
          <div className="text-white font-bold md:hidden col-span-1">
            Blue Team
          </div>
          <div className="col-span-2 md:text-left text-right">
            <b>Name:</b> {item.Teams[1].Members[0].Name}
            <br />
            <b>Kills: </b> {item.Teams[1].Members[0].Kills}
          </div>
        </div>
        <div className="text-white col-span-5 md:col-span-1 grid grid-cols-3 md:block">
          <div className="text-white font-bold md:hidden col-span-1">Mode</div>
          <div className="col-span-2 md:text-left text-right">
            {item.GameMode}
          </div>
        </div>
        <div className="text-white col-span-5 md:col-span-1 grid grid-cols-3 md:block">
          <div
            className="text-white font-bold md:hidden col-span-1 pb-2"
            style={{ borderBottom: "1px solid #ffffff5c" }}
          >
            Date
          </div>
          <div
            className="col-span-2 md:text-left text-right pb-2 md:border-0 border-b"
            style={{ borderColor: "#ffffff5c" }}
          >
            {item.DateTime}
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="section" style={{ height: "100%" }}>
      <div className="container h-full">
        <h2 className="txt-weapon">Daily Gamers</h2>
        <div className="grid-cols-7 gap-4 bg-custom-gray2 mt-5 p-2 text-left md:grid hidden">
          <div className="text-white font-bold">ID</div>
          <div className="text-white font-bold">Round #</div>
          <div className="text-white font-bold">Red Team</div>
          <div className="text-white font-bold">Blue Team</div>
          <div className="text-white font-bold">Mode</div>
          <div className="text-white font-bold flex items-center">
            Date
            <Sort
              onClick={() => sortByDate()}
              style={{ cursor: "pointer", marginLeft: 5, fill: "white" }}
            />
          </div>
          <div className="text-white font-bold">Winner</div>
        </div>
        {data.length > 0 ? (
          data.map((item, index) => (
            <div
              className={`grid grid-cols-2 md:grid-cols-7 md:gap-4 border-b pb-3 pt-3 pr-2 pl-2 border-b-slate-400 font-light ${
                index % 2 === 0 ? "bg-opacity-25 bg-red-500" : ""
              }`}
              key={item._id}
            >
              <div className="text-white col-span-2 md:col-span-1 break-words text-left">
                <div className="grid grid-cols-3 md:block">
                  <div className="text-white font-bold md:hidden col-span-1">
                    ID
                  </div>
                  <div className="col-span-2 md:text-left text-right">
                    {item._id}
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-5">
                <div className="grid grid-cols-3 md:block">
                  <div className="text-white font-bold md:hidden text-left">
                    Rounds
                  </div>
                  <div className="col-span-3 md:text-left text-right">
                    {displayRounds(item.rounds)}
                  </div>
                </div>
              </div>
              <div className="text-white col-span-2 md:col-span-1 text-left">
                <div className="grid grid-cols-3 md:block">
                  <div className="text-white font-bold md:hidden col-span-1">
                    Winner
                  </div>
                  <div className="col-span-2 md:text-left text-right">
                    {item.Winner}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h3 className="txt-weapon">No data to show</h3>
        )}
      </div>
    </div>
  );
};

export default DailyGamers;
