/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from "react";
import NavigationWidget from "../components/NavigationWidget";
import Btn from "../components/Inputs/Btn";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Scrollbar, Mousewheel, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import { ProgressBar } from "../components/ProgressBar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { NewCard } from "../components/NewCard";

// Pets
import EagleIcon from "../assets/svg/pets/eagle/EagleIcon";
import ArmadilloIcon from "../assets/svg/pets/armadillo/ArmadilloIcon";
import CatIcon from "../assets/svg/pets/cat/CatIcon";
import SnakeIcon from "../assets/svg/pets/snake/SnakeIcon";
import DogIcon from "../assets/svg/pets/dog/DogIcon";
import OctopusIcon from "../assets/svg/pets/octopus/OctopusIcon";
import TurtleIcon from "../assets/svg/pets/turtle/TurtleIcon";
import JaguarIcon from "../assets/svg/pets/jaguar/JaguarIcon";
import MonkeyIcon from "../assets/svg/pets/monkey/MonkeyIcon";

import Eagle from "../assets/img/pets/eagle/Eagle.png";
import Armadillo from "../assets/img/pets/armadillo/Armadillo.png";
import Cat from "../assets/img/pets/cat/cat.png";
import Snake from "../assets/img/pets/snake/snake.png";
import Dog from "../assets/img/pets/dog/dog.png";
import Octopus from "../assets/img/pets/octopus/octopus.png";
import Turtle from "../assets/img/pets/turtle/turtle.png";
import Jaguar from "../assets/img/pets/jaguar/jaguar.png";
import Monkey from "../assets/img/pets/monkey/monkey.png";

import DogFastLoad from "../assets/img/pets/dog/wfast_load.png";
import DogTurret from "../assets/img/pets/dog/wturret.png";
import MonkeyUpKick from "../assets/img/pets/monkey/up_kick.png";
import MonkeyVitalPulse from "../assets/img/pets/monkey/vital_pulse.png";
import SnakeBullet from "../assets/img/pets/snake/bullet.png";
import SnakeRunner from "../assets/img/pets/snake/runner.png";
import TurtleDefensiveWall from "../assets/img/pets/turtle/defensive_wall.png";
import TurtleVitalPulse from "../assets/img/pets/turtle/vital_pulse.png";
import CatHealthRegeneration from "../assets/img/pets/cat/health_regeneration.png";
import CatSneak from "../assets/img/pets/cat/sneak.png";
import newsOne from "../assets/img/news1.png";
import NFTrade from "../assets/img/NFTrade.png";
import OctopusImg from "../assets/img/Octopus.png";
import EasyLogin from "../assets/img/easylogin.png";
import QorXPass from "../assets/img/QorXPassImage.png"
import HowToPlay from "../assets/img/HowToPlay.png"
import imxTwoLayers from "../assets/img/imxTwoLayers.png";
import EggToPet from "../assets/img/eggToPet.png";
import OwnedXOnetap from "../assets/img/OwnedXOnetap.png";
import Spielworks from "../assets/img/spielworks.jpg";
import JaguarDash from "../assets/img/pets/jaguar/dash.png";
import JaguarFeather from "../assets/img/pets/jaguar/feather.png";
import OctopusInk from "../assets/img/pets/octopus/ink.png";
import OctopusRefelex from "../assets/img/pets/octopus/refelexes.png";

// Placeholder
import placeholderImage from "../assets/img/placeholder.png";

// Weapons
import EvaIcon from "../assets/svg/weapons/eva";
import QuickhammerIcon from "../assets/svg/weapons/quickhammer";
import R_46Icon from "../assets/svg/weapons/R-46";
import Ra_x45Icon from "../assets/svg/weapons/ra-x45";
import SniperIcon from "../assets/svg/weapons/sniper";

import EvaImage from "../assets/img/weapons/Eva.png";
import R_46Image from "../assets/img/weapons/R-46.png";
import QuickhammerImage from "../assets/img/weapons/Quickhammer.png";
import SniperImage from "../assets/img/weapons/Sniper.png";
import RA_X45 from "../assets/img/weapons/RA-X45.png";

// Maps
import EgyptIcon from "../assets/svg/maps/egypt";
import ToxicIcon from "../assets/svg/maps/toxic";
import StonehallaIcon from "../assets/svg/maps/stonehalla";
import ArticIcon from "../assets/svg/maps/artic";

import CastleBG from "../assets/img/maps/castleBG.jpg";
import EgyptBG from "../assets/img/maps/egyptBG.jpg";
import ToxicBG from "../assets/img/maps/toxicBG.jpg";
import StonehallaBG from "../assets/img/maps/stonehallaBG.png";
import ArticBG from "../assets/img/maps/articBG.jpg";

import Reveal from "../assets/img/pets/eagle/Reveal.png";
import Quick from "../assets/img/pets/eagle/quick.png";
import Swirl from "../assets/img/pets/armadillo/Swirl.png";
import Endurance from "../assets/img/pets/armadillo/Endurance.png";
import FooterHome from "../components/FooterHome";
const pets = [
  {
    name: "Jaguar",
    desc1: {
      title: "DASH",
      text: (
        <p>
          Allows agent to do two consecutive dashes that give you the ability to
          move 10x faster than your opponent.
          <br /> Duration: 3s
        </p>
      ),
      img: JaguarDash,
    },
    desc2: {
      title: "FEATHERFALL",
      text: <p>Damage received from falls is reduced by 20 percent.</p>,
      img: JaguarFeather,
    },
    img: Jaguar,
    icon: <JaguarIcon />,
  },
  {
    name: "Octopus",
    desc1: {
      title: "INK CLONE",
      text: (
        <p>
          Creates an ink attack that allows the octopus to blind the enemy's
          screen and minimap vision.
        </p>
      ),
      img: OctopusInk,
    },
    desc2: {
      title: "QUICK REFLEXES",
      text: (
        <p>
          The player has a higher speed of reaction, which slightly reduces
          aiming time and improves accuracy in combat.
        </p>
      ),
      img: OctopusRefelex,
    },
    img: Octopus,
    icon: <OctopusIcon />,
  },
  {
    name: "Dog",
    desc1: {
      title: "TURRET",
      text: (
        <p>
          You acquire the ability to deploy a turret that will attack the
          nearest enemy.
          <br /> Duration: 20s
        </p>
      ),
      img: DogTurret,
    },
    desc2: {
      title: "FAST RELOAD",
      text: <p>Reload your weapon 15% faster.</p>,
      img: DogFastLoad,
    },
    img: Dog,
    icon: <DogIcon />,
  },
  {
    name: "Turtle",
    desc1: {
      title: "TACTICAL COVER",
      text: (
        <p>
          Generates a static barrier (stays in the place of creation and does
          not follow you), temporary, to hide and attack.
          <br />
          Duration: 5s{" "}
        </p>
      ),
      img: TurtleDefensiveWall,
    },
    desc2: {
      title: "VITAL PULSE",
      text: (
        <p>You recover 25 of your health when your life is below 15 pts.</p>
      ),
      img: TurtleVitalPulse,
    },
    img: Turtle,
    icon: <TurtleIcon />,
  },
  {
    name: "Snake",
    desc1: {
      title: "SPIT",
      text: (
        <p>
          Your bullets have snake venom attribute, causing your enemy to gain 2
          seconds of additional damage for each bullet hit.
          <br /> Duration: 3s
        </p>
      ),
      img: SnakeBullet,
    },
    desc2: {
      title: "SNEAKY",
      text: <p> Runs 1.5 times faster after taking damage.</p>,
      img: SnakeRunner,
    },
    img: Snake,
    icon: <SnakeIcon />,
  },
  {
    name: "Eagle",
    desc1: {
      title: "REVEAL",
      text: (
        <p>
          The Eagle reveals the position of your enemies
          <br />
          Duration: 3s{" "}
        </p>
      ),
      img: Reveal,
    },
    desc2: {
      title: "QUICK AIM",
      text: <p>It will allow you to aim your weapon 35% faster</p>,
      img: Quick,
    },
    img: Eagle,
    icon: <EagleIcon />,
  },
  {
    name: "Monkey",
    desc1: {
      title: "UP KICK",
      text: <p>Kung Fu kick that hurts your enemies at close range.</p>,
      img: MonkeyUpKick,
    },
    desc2: {
      title: "HEALING SUPPLIED",
      text: (
        <p> You recover 10 pts of health when your life is below 10 pts.</p>
      ),
      img: MonkeyVitalPulse,
    },
    img: Monkey,
    icon: <MonkeyIcon />,
  },
  {
    name: "Armadillo",
    desc1: {
      title: "SWIRL",
      text: (
        <p>
          The Armadillo turns into a deadly whirlpool that sends crippling
          damage to your opponent
          <br /> Duration: 3s
        </p>
      ),
      img: Swirl,
    },
    desc2: {
      title: "ENDURANCE",
      text: (
        <p>
          {" "}
          20% Damage reduction when you health
          <br /> is below 10%
        </p>
      ),
      img: Endurance,
    },
    img: Armadillo,
    icon: <ArmadilloIcon />,
  },
  {
    name: "Cat",
    desc1: {
      title: "DEADLY STEALTH",
      text: (
        <p>
          Allows you to move silently at 150% of your normal speed.
          <br /> Duration: 5s
        </p>
      ),
      img: CatSneak,
    },
    desc2: {
      title: "FRENETIC REGENERATION",
      text: <p> For every second you run you recover 2.5 health.</p>,
      img: CatHealthRegeneration,
    },
    img: Cat,
    icon: <CatIcon />,
  },
];
const weapons = [
  {
    type: "SUB-MACHINEGUN",
    name: "EVA",
    popularityPercentage: "17.0",
    killAverage: "1.1",
    averageKillDistance: "08M",
    image: EvaImage,
    icon: <EvaIcon />,
    statistics: {
      damage: "41",
      effectiveRage: "80",
      accuracy: "76",
      stability: "89",
      aimAssist: "57",
    },
  },
  {
    type: "ASSAULT RIFLE",
    name: "Ra-x45",
    popularityPercentage: "07.5",
    killAverage: "1.1",
    averageKillDistance: "08M",
    image: RA_X45,
    icon: <Ra_x45Icon />,
    statistics: {
      damage: "42",
      effectiveRage: "80",
      accuracy: "65",
      stability: "31",
      aimAssist: "53",
    },
  },
  {
    type: "PISTOL",
    name: "R-46",
    popularityPercentage: "07.5",
    killAverage: "1.1",
    averageKillDistance: "08M",
    image: R_46Image,
    icon: <R_46Icon />,
    statistics: {
      damage: "34",
      effectiveRage: "80",
      accuracy: "65",
      stability: "31",
      aimAssist: "53",
    },
  },
  {
    type: "SNIPER RIFLE",
    name: "Lancer",
    popularityPercentage: "07.5",
    killAverage: "1.1",
    averageKillDistance: "08M",
    image: SniperImage,
    icon: <SniperIcon />,
    statistics: {
      damage: "75",
      effectiveRage: "100",
      accuracy: "65",
      stability: "31",
      aimAssist: "53",
    },
  },
  {
    type: "SHOTGUN",
    name: "Quickhammer",
    popularityPercentage: "07.5",
    killAverage: "1.1",
    averageKillDistance: "08M",
    image: QuickhammerImage,
    icon: <QuickhammerIcon />,
    statistics: {
      damage: "20",
      effectiveRage: "40",
      accuracy: "65",
      stability: "31",
      aimAssist: "53",
    },
  },
];
const maps = [
  {
    name: "Scorch",
    description:
      "Embark on an adventure in Scorch and explore the mystical land of the pharaohs. Uncover ancient pyramids and mysterious underground passages as you confront your enemies in a captivating environment of exotic desert.",
    image: EgyptBG,
    icon: <EgyptIcon />,
  },
  {
    name: "Toxic",
    description:
      "If the air gets hard to breathe, then you must have found yourself in our Toxic map. This map is an enclosed portion of an old chemical plant found in the middle of nowhere. Getting to the top of the center building will give you a vantage point, but you have to be fast up that ladder.",
    image: ToxicBG,
    icon: <ToxicIcon />,
  },
  {
    name: "Stonehalla",
    description:
      "Danger at every turn. The Stonehalla map is inspired by viking style villages, but you are in no village. This map is an obstacle course of walls and structures setup for gladiator style fighting. In this map, don't lose your head peeking out of windows.",
    image: StonehallaBG,
    icon: <StonehallaIcon />,
  },
  {
    name: "Arctic",
    description:
      "Burr it is cold here, the arctic map is made up of beautiful snowy mountain scenery with deadly vantage points. You find yourself in a remote mountain with some sort of research facility, the mystery is what was in that cage, and where is it now. Watch out for high ground positioning.",
    image: ArticBG,
    icon: <ArticIcon />,
  },
  {
    name: "Corridor",
    description:
      "Explore the mysterious ambiance of Corridor. Navigate through its intriguing corridors, illuminated by the gentle moonlight, facing enemies at every corner. Discover hidden secrets and concealed danger in the narrow castle corridors, immersing yourself in an experience filled with intrigue and suspense.",
    image: CastleBG,
    icon: <ArticIcon />,
  },
];
const news = [
  {
    image: newsOne,
    title: "One Tap Beta Release: A New Era of Gaming is on the Horizon!",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Play One Tap",
    },
    timeAgo: "July 26, 2023",
    link: "/one-tap-beta-release",
  },
  {
    image: NFTrade,
    title: "NFTrade | Blog | One Tap: The Next Web3 Esports Sensation",
    content: "",
    game: {
      icon: placeholderImage,
      name: "NFTrade",
    },
    timeAgo: "November 22, 2023",
    link: "https://nftrade.com/blog/one-tap-the-next-web3-esports-sensation",
  },
  {
    image: OctopusImg,
    title: "Octopus Pet Drop",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 2, 2023",
    link: "https://qorbiworld.medium.com/octopus-pet-drop-28b1d9138e15",
  },
  {
    image: EggToPet,
    title: "Qorbi World Pet Egg System",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 4, 2023",
    link: "https://qorbiworld.medium.com/qorbi-world-pet-egg-system-2ad7a402ed75",
  },
  {
    image: EasyLogin,
    title: "One Tap Easy Login & Wallet Connection with SphereOne",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 4, 2023",
    link: "https://qorbiworld.medium.com/one-tap-easy-login-wallet-connection-with-sphereone-0bf4b24e6d35",
  },
  {
    image: OwnedXOnetap,
    title: "One Tap on Owned",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 5, 2023",
    link: "https://qorbiworld.medium.com/one-tap-on-owned-fea846950b88",
  },
  {
    image: Spielworks,
    title: "Spielworks and Qorbi World — a match made in gaming heaven",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "February 21, 2024",
    link: "https://medium.com/wombat/spielworks-and-qorbi-world-a-match-made-in-gaming-heaven-5053f9165245",
  },
  {
    image: imxTwoLayers,
    title: "Between 2 Layers",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Immutable X",
    },
    timeAgo: "February 24, 2024",
    link: "https://www.youtube.com/watch?v=7lV_uGh6ndE",
  },
  {
    image: HowToPlay,
    title: "How to Play One Tap",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "May 17, 2024",
    link: "https://qorbiworld.medium.com/how-to-play-one-tap-6d7605372c51",
  },
  {
    image: QorXPass,
    title: "QOR-X Pass Lifetime Pass",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "May 18, 2024",
    link: "https://qorbiworld.medium.com/qor-x-pass-lifetime-pass-084f6dc96436",
  },
];
const invertedNews = news.reverse();
const FirstSection = () => {
  return (
    <div className="banner relative section">
      <div className="container">
        <div className="lg:absolute left-0 w-full text-left pl-28 text-container">
          <h2 className="txt-white">EXPERIENCE THE THRILL FIRST </h2>
          <h2 className="gradient mb-5">ONE TAP'S BETA IS HERE!</h2>
          <div className="md:flex lg:justify-start justify-center">
            <Btn
              action={() =>
                window.open(
                  "https://playonetap.com/download",
                  "_blank"
                )
              }
              label="Download Now"
              style="style1"
            />
            <div className="w-7" />
            {/* <Btn
              action={() =>
                window.open("https://playonetap.com/leaderboard/Leaderboard%20Tournament%20One", "_blank")
              }
              label="Leaderboard Ranking"
              style="style2"
            /> */}
            <Btn
              action={() =>
                window.open("https://playonetap.com/mobile-registration", "_blank")
              }
              label="Join Mobile Waitlist"
              style="style2"
            />
          </div>
        </div>
      </div>
      <div className="bg-center bg-cover banner-bg lg:absolute" />
    </div>
  );
};
const SecondSection = () => {
  const [petSelected, setPetSelected] = useState(0);
  return (
    <div className="section pets-container">
      <div className="container h-full">
        <div className="lg:relative h-full bottom-1 left-0 w-full text-left pl-28 text-container">
          {pets.map((item, index) => (
            <div
              key={"pet" + "-" + index}
              className={`pet lg:flex lg:items-center${petSelected === index ? " currentPet" : ""
                }`}
            >
              <div className="lg:pb-24">
                <div className="mb-5 cont">
                  <h2 className="txt-white mb-5">{item.name}</h2>
                  <img src={item.desc1.img} alt="reveal" className="w-10" />
                  <h3 className="text-custom-red2 uppercase text-lg font-semibold my-3">
                    {item.desc1.title}
                  </h3>
                  <div className="text-white font-medium text-sm tracking-widest leading-loose">
                    {item.desc1.text}
                  </div>
                </div>
                <div>
                  <img src={item.desc2.img} alt="reveal" className="w-10" />
                  <h3 className="text-custom-red2 uppercase text-lg font-semibold my-3">
                    {item.desc2.title}
                  </h3>
                  <div className="text-white font-medium text-sm tracking-widest leading-loose lg:max-w-sm">
                    {item.desc2.text}
                  </div>
                </div>
                <div
                  style={{ backgroundImage: `url(${item.img})` }}
                  className="absolute pet-bg"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="pet-actions absolute bottom-5 w-full">
          <ul className="flex items-center justify-between">
            {pets.map((item, index) => (
              <li
                key={index}
                className={`text-center ${index === petSelected ? "active" : ""
                  }`}
                onClick={() => setPetSelected(index)}
              >
                <div className="icon m-auto">{item.icon}</div>
                <span className="font-medium text-xs inline-block mt-2">
                  {item.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
const ThirdSection = () => {
  const [weaponSelected, setWeaponSelected] = useState(0);
  return (
    <div className="section pets-container weapon-cont">
      <div className="container h-full">
        <div className="lg:relative h-full  bottom-1 left-0 w-full text-left pl-28 text-container">
          {weapons.map((item, index) => (
            <div
              key={"pet" + "-" + index}
              className={`pet lg:flex lg:items-center${weaponSelected === index ? " currentPet" : ""
                }`}
            >
              <div className="lg:pb-24">
                <div className="sm:flex w-full cont">
                  <div className="w-full">
                    <h2 className="txt-weapon">{item.type}</h2>
                    <h2 className="txt-white">{item.name}</h2>
                    <div className="sm:flex cont-info">
                      <div>
                        <h2 className="txt-weapon-popularity mb-5">
                          Main Weapon Popularity
                        </h2>
                        <div className="flex percentage justify-between">
                          <h2 className="txt-weapon-popularity-percentage">
                            {item.popularityPercentage}%
                          </h2>
                          <h2
                            className="txt-weapon-popularity-percentage"
                            style={{
                              width: 50,
                              height: 50,
                              marginTop: `5px`,
                              marginLeft: `30px`,
                            }}
                          >
                            <CircularProgressbar
                              value={item.popularityPercentage}
                              strokeWidth={22}
                              styles={buildStyles({
                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: "butt",

                                // Colors
                                pathColor: `#DC3838`,
                                trailColor: `rgba(255, 255, 255, 0.1)`,
                              })}
                            />
                          </h2>
                        </div>
                        <div className="weapon-statistic mt-4 flex justify-between">
                          <div className="attribute mr-5">
                            <h3>Popularity</h3>
                            <h3>Average Kills</h3>
                            <h3>Average Kills Distance</h3>
                          </div>
                          <div className="value">
                            <h3 className="weapon-properties">
                              {item.popularityPercentage}%
                            </h3>
                            <h3 className="weapon-properties">
                              {item.killAverage}
                            </h3>
                            <h3 className="weapon-properties">
                              {item.averageKillDistance}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="sm:ml-20">
                        <div className="weapon-statistic">
                          <p>Weapon Statistics</p>
                          <div className="flex justify-between">
                            <div className="attribute sm:mr-5">
                              <h3>Damage</h3>
                              <h3>Eff. Range</h3>
                              <h3>Accuracy</h3>
                              <h3>Stability</h3>
                              <h3>Aim Assist</h3>
                            </div>
                            <div className="value">
                              <h3 className="flex items-center">
                                <ProgressBar
                                  percentage={item.statistics.damage}
                                />
                                <span className="ml-5">
                                  {item.statistics.damage}
                                </span>
                              </h3>
                              <h3 className="flex items-center">
                                <ProgressBar
                                  percentage={item.statistics.effectiveRage}
                                />
                                <span className="ml-5">
                                  {item.statistics.effectiveRage}
                                </span>
                              </h3>
                              <h3 className="flex items-center">
                                <ProgressBar
                                  percentage={item.statistics.accuracy}
                                />
                                <span className="ml-5">
                                  {item.statistics.accuracy}
                                </span>
                              </h3>
                              <h3 className="flex items-center">
                                <ProgressBar
                                  percentage={item.statistics.stability}
                                />
                                <span className="ml-5">
                                  {item.statistics.stability}
                                </span>
                              </h3>
                              <h3 className="flex items-center">
                                <ProgressBar
                                  percentage={item.statistics.aimAssist}
                                />
                                <span className="ml-5">
                                  {item.statistics.aimAssist}
                                </span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ backgroundImage: `url(${item.image})` }}
                className="absolute weapon-bg"
              ></div>
            </div>
          ))}
        </div>
        <div className="pet-actions absolute bottom-5 w-full weapon-actions-cont">
          <ul className="flex items-center justify-between">
            {weapons.map((item, index) => (
              <li
                key={index}
                className={`text-center ${index === weaponSelected ? "active" : ""
                  }`}
                onClick={() => setWeaponSelected(index)}
              >
                <div className="icon m-auto">{item.icon}</div>
                <span className="font-medium text-xs inline-block mt-2">
                  {item.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
const FourthSection = () => {
  const [mapSelected, setMapSelected] = useState(0);
  return (
    <div
      id="maps"
      className="section pets-container map-bg"
      style={{ backgroundImage: `url(${maps[mapSelected].image})` }}
    >
      <div className="container h-full">
        <div className="lg:relative h-full bottom-1 left-0 w-full text-left pl-28 text-container">
          {maps.map((item, index) => (
            <div
              key={"pet" + "-" + index}
              className={`lg:flex lg:items-center pet${mapSelected === index ? " currentPet" : ""
                }`}
            >
              <div className="lg:mb-5 lg:mr-5">
                <h2 className="txt-white">{item.name}</h2>
                <h2 className="map-description">{item.description}</h2>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="pet-actions absolute bottom-5 w-full weapon-actions-cont">
            <ul className="flex items-center justify-between">
              {maps.map((item, index) => (
                <li
                  key={index}
                  className={`text-center ${index === mapSelected ? "active" : ""
                    }`}
                  onClick={() => setMapSelected(index)}
                >
                  <div className="icon m-auto">{item.icon}</div>
                  <span className="font-medium text-xs inline-block mt-2">
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
const FifthSection = () => {
  return (
    <div className="relative section" id="news">
      <div className="container">
        <div className="flex justify-center sm:mt-10 title">
          <h2 className="txt-white">
            LATEST <span className="gradient">NEWS</span>
          </h2>
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", maxHeight: '685px', overflowY: 'auto', marginLeft: "8%" }}>
        {invertedNews.map((item, index) => (
          <NewCard
            key={index}
            image={item.image}
            title={item.title}
            content={item.content}
            game={item.game}
            link={item.link}
            timeAgo={item.timeAgo}
          />
        ))}
      </div>
    </div>
  );
};
const Home = () => {
  const { isSphereOneConnected, isEthereumConnected } = useOneTapProvider();
  const [active, setActive] = useState(0);
  const swiperRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    const storedItem = localStorage.getItem('closedPath');
    if (storedItem && (isSphereOneConnected || isEthereumConnected)) {
      localStorage.removeItem('closedPath');
      navigate(storedItem)
    }
  })
  return (
    <div className="relative home">
      <div className="lg:block hidden">
        <NavigationWidget
          active={active}
          onClick={(val) => swiperRef.current.slideTo(val)}
        />
        <Swiper
          slidesPerView="1"
          mousewheel={true}
          keyboard={true}
          direction="vertical"
          modules={[Keyboard, Scrollbar, Mousewheel]}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={(val) => setActive(val.realIndex)}
        >
          <SwiperSlide>{FirstSection()}</SwiperSlide>
          <SwiperSlide>{SecondSection()}</SwiperSlide>
          <SwiperSlide>{ThirdSection()}</SwiperSlide>
          <SwiperSlide>{FourthSection()}</SwiperSlide>
          <SwiperSlide>{FifthSection()}</SwiperSlide>
        </Swiper>
      </div>
      {/* mobile version */}
      <div className="lg:hidden">
        {FirstSection()}
        {SecondSection()}
        {ThirdSection()}
        {FourthSection()}
        {FifthSection()}
      </div>
      <FooterHome/>
    </div>
  );
};

export default Home;
