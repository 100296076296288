import * as React from "react"
import { SVGProps } from "react"
const TurtleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192.109 207.388"
    width={props.width ? props.width : 25}
    height={props.height ? props.height : 25}
    {...props}
  >
    <g fill="#b7b7b7" data-name="Grupo 17160">
      <path
        d="m69.494 122.025-9.75-22a5 5 0 0 0-4.61-3h-26.45a5 5 0 0 0-4.52 7.27l20.57 41.73a5 5 0 0 0 8.48.9l15.63-19.76a5 5 0 0 0 .65-5.14Z"
        data-name="Trazado 70709"
      />
      <path
        d="m91.164 137.565-12.66-7.25a5.05 5.05 0 0 0-6.48 1.25l-16.7 21.25a5 5 0 0 0 1.31 7.41l29.37 18.09a5 5 0 0 0 7.68-4.29v-32.08a5 5 0 0 0-2.52-4.38Z"
        data-name="Trazado 70710"
      />
      <path
        d="m28.254 90.545 26.53-.68a5.06 5.06 0 0 0 4.54-3.15l6.14-15.12a5 5 0 0 0-1.16-5.51l-18.38-17.87a5 5 0 0 0-8.15 1.65l-14.3 33.68a5 5 0 0 0 4.78 7Z"
        data-name="Trazado 70711"
      />
      <path
        d="M67.784 62.095a5 5 0 0 0 5.62 1.22l17.21-7.3a5 5 0 0 0 3.07-4.64v-23.18a5 5 0 0 0-6.85-4.71l-33.79 13a5 5 0 0 0-1.85 8.18Z"
        data-name="Trazado 70712"
      />
      <path
        d="m101.504 55.965 17.21 7.3a5 5 0 0 0 5.63-1.17l16.58-17.48a5 5 0 0 0-1.85-8.18l-33.79-13a5 5 0 0 0-6.85 4.71v23.17a5 5 0 0 0 3.07 4.65Z"
        data-name="Trazado 70713"
      />
      <path
        d="m187.784 70.315-11.1-26.16a54 54 0 0 0-30.33-29.31l-27.69-10.65a63 63 0 0 0-45.21 0l-27.68 10.65a54 54 0 0 0-30.34 29.31l-11.1 26.16a54.38 54.38 0 0 0 1.27 45.32l22.16 44.93a52.17 52.17 0 0 0 19.41 21.34l34.83 21.5a26.76 26.76 0 0 0 28.09 0l34.83-21.5a52.26 52.26 0 0 0 19.42-21.34l22.15-44.93a54.38 54.38 0 0 0 1.29-45.32Zm-18.65 46.21-16 32.37a52.349 52.349 0 0 1-19.42 21.35l-23.65 14.59a26.76 26.76 0 0 1-28.09 0l-23.65-14.59a52.35 52.35 0 0 1-19.42-21.35l-16-32.38a54.43 54.43 0 0 1-1.28-45.3l5.98-13.82a54.06 54.06 0 0 1 30.33-29.31l15.55-6a62.93 62.93 0 0 1 45.21 0l15.55 6a54.06 54.06 0 0 1 30.33 29.31l5.87 13.83a54.38 54.38 0 0 1-1.31 45.31Z"
        data-name="Trazado 70714"
      />
      <path
        d="m116.444 120.555 12.23-27.92-9-22-13.74-5.75a25.579 25.579 0 0 0-19.77 0l-13.74 5.75-9 22 12.23 27.92 14.74 8.6a11.18 11.18 0 0 0 11.29 0Z"
        data-name="Trazado 70715"
      />
      <path
        d="M163.434 97.055h-26.45a5 5 0 0 0-4.61 3l-9.75 22a5 5 0 0 0 .66 5.17l15.62 19.76a5 5 0 0 0 8.48-.9l20.57-41.77a5 5 0 0 0-4.52-7.26Z"
        data-name="Trazado 70716"
      />
      <path
        d="M154.344 49.865a5 5 0 0 0-8.15-1.65l-18.38 17.87a5 5 0 0 0-1.15 5.51l6.13 15.12a5.07 5.07 0 0 0 4.54 3.15l26.54.68a5 5 0 0 0 4.77-7Z"
        data-name="Trazado 70717"
      />
      <path
        d="M120.094 131.525a5.05 5.05 0 0 0-6.49-1.21l-12.66 7.29a5 5 0 0 0-2.52 4.37v32.08a5 5 0 0 0 7.68 4.29l29.37-18.12a5 5 0 0 0 1.31-7.41Z"
        data-name="Trazado 70718"
      />
    </g>
  </svg>
)
export default TurtleIcon
