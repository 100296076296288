import * as React from "react"
import { SVGProps } from "react"
const JaguarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192.123 193.018"
    width={props.width ? props.width : 25}
    height={props.height ? props.height : 25}
    {...props}
  >
    <path
      fill="#b7b7b7"
      d="m173.593 41.878 5.93 15.31a62.14 62.14 0 0 0 12.39-42.62l-.94-11.38-35.73 1.29a393.529 393.529 0 0 0-118.39 0l-35.7-1.29-.94 11.38a62.09 62.09 0 0 0 12.38 42.62l5.93-15.31a21.7 21.7 0 0 0-3.23-21.29 6.59 6.59 0 0 1 6.65 2.21 21.71 21.71 0 0 1 3.65 19.68l-7 22.56-3.27 10.83 5.79 38.28s34.58 8.36 34.58 36.35v21.23l16.57 20 23.81 1.29 23.8-1.29 16.57-20v-21.23c0-28 34.58-36.35 34.58-36.35l5.79-38.28-3.26-10.83-6.96-22.56a21.67 21.67 0 0 1 3.59-19.71 6.6 6.6 0 0 1 6.65-2.21 21.68 21.68 0 0 0-3.24 21.32Zm-50 131.81a34.062 34.062 0 0 1-18.24 6.21l-7.85.33h-2.83l-7.85-.33a34.092 34.092 0 0 1-18.23-6.21l-3.22-18.66 10.94 16.73 3.7-4.1 5.36 7 5.42-6.28 5.31 5.24 5.31-5.24 5.41 6.28 5.36-7 3.7 4.1 10.94-16.73Zm26.27-117.56a14.71 14.71 0 0 1-10.73 11.11 14.89 14.89 0 0 1-16.85-7.37 14.6 14.6 0 0 0-2.52 8.19v10.78a14.809 14.809 0 0 1-.52 3.92 6.26 6.26 0 0 1-2.55 3.58l-20.57 13.89a9.77 9.77 0 0 0 8.47 8.67c10.57 1.38 28.35 3.7 28.35 3.72a8.83 8.83 0 0 0 9.36-3.31l2.5-3.39a19 19 0 0 0 2.7-17.46l-2.32-6.81 3.51 5.84a19 19 0 0 1-.82 20.9l-4.23 5.91a9.68 9.68 0 0 1-11.14 3.48l-12.74 30.78-1.41-19.2-.84-11.52-2.47 2-4.13 3.32-3.31-4.12-2-2.48s-2.71 2.36-4.27 3.67l-1 .84c-.48.32-4.18-6.11-4.18-6.12s-3.7 6.44-4.18 6.12c-.13-.09-.52-.41-1-.84-1.56-1.31-4.27-3.67-4.27-3.67l-2 2.48-3.32 4.12-4.12-3.32-2.48-2-.84 11.52-1.41 19.2-12.74-30.78a9.68 9.68 0 0 1-11.14-3.48l-4.22-5.9a19.05 19.05 0 0 1-.83-20.91l3.52-5.84-2.46 6.83a19 19 0 0 0 2.7 17.46l2.5 3.39a8.84 8.84 0 0 0 9.37 3.31s17.85-2.35 28.34-3.71a9.81 9.81 0 0 0 8.44-8.4v-.28l-20.54-13.87a6.311 6.311 0 0 1-2.55-3.58 15.233 15.233 0 0 1-.52-3.92v-10.78a14.6 14.6 0 0 0-2.52-8.19 14.85 14.85 0 1 1-24.29-16.75l-.18-.07-9.65-3.39h14.25a23.42 23.42 0 0 1 23.52 23.4v7.49a7.619 7.619 0 0 0 5.278 7.25 56.36 56.36 0 0 0 34.47 0 7.62 7.62 0 0 0 5.29-7.25v-7.49a23.39 23.39 0 0 1 23.51-23.4h14.28l-9.66 3.39-.17.07a14.88 14.88 0 0 1 3.29 12.97Z"
      data-name="Trazado 70706"
    />
  </svg>
)
export default JaguarIcon
