import * as React from "react"
import { SVGProps } from "react"
const MegaphoneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 2"
        viewBox="0 0 343.21 352.91"
        width={props.width ? props.width : 20}
        height={props.height ? props.height : 20}
        {...props}
    >
        <g data-name="Capa 1">
            <path d="M175.47 221.78v-14.1c0-53.01.02-106.02-.07-159.03 0-2.71.53-4.17 3.3-5.08 42.4-13.94 84.77-27.98 127.14-42.01 18.25-6.04 36.12 6.18 37.24 25.45.06.99.12 1.99.12 2.98 0 68.85.01 137.7 0 206.55 0 8.05-1.91 15.51-7.72 21.43-8.89 9.06-19.72 10.85-31.34 7.02-42.72-14.09-85.36-28.43-128.02-42.69-.2-.07-.34-.27-.64-.52ZM159.34 225.21h-4.09c-26.43 0-52.87.09-79.3-.03-29.62-.13-51.45-13.91-66.5-38.91-5.48-9.11-8.89-19.14-9.13-29.85-.33-14.58-.47-29.17-.1-43.74C.68 94.55 7.9 79.14 20.1 65.79c11.06-12.11 24.76-19.35 40.53-22.88 4.52-1.01 9.27-1.35 13.91-1.37 28.17-.12 56.34-.06 84.8-.06v183.73ZM70.56 241.11h6.48c31.41 0 62.82.03 94.23-.05 2.62 0 3.67.48 4.35 3.43 3.89 16.82 9.1 33.38 12.07 50.35 2.49 14.23-1.02 27.82-10.68 39.37-7.09 8.48-15.86 14.22-26.47 16.9-15.26 3.86-29.53 1.52-42.5-7.74-12.81-9.14-18.29-22.42-21.77-37.09-4.95-20.86-10.32-41.62-15.49-62.43-.2-.8-.14-1.66-.22-2.75Z" />
        </g>
    </svg>
)
export default MegaphoneIcon