import * as React from "react"
import { SVGProps } from "react"
const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.91 14.916"
    width={props.width ? props.width : 14.91}
    height={props.height ? props.height : 14.916}
    {...props}
  >
    <path
      d="M13.528 5.222a2.5 2.5 0 0 1 0 4.472l-9.91 4.955A2.5 2.5 0 0 1 0 12.413v-9.91A2.5 2.5 0 0 1 3.618.267Z"
      data-name="Trazado 70710"
    />
  </svg>
)
export default PlayIcon
