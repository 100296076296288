import * as React from "react"
import { SVGProps } from "react"
const WeaponIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 2"
        viewBox="0 0 408.85 335.49"
        width={props.width ? props.width : 20}
        height={props.height ? props.height : 20}
        {...props}
    >
        <g data-name="Capa 1">
            <path d="M0 119.79h380.03c-1.65 3.29-3.19 6.35-4.72 9.41-5.07 10.17-10.08 20.37-15.22 30.51-2.69 5.3-6.73 8.1-13.1 8.08-42.17-.16-84.34-.08-126.51-.08h-4.9c0 1.36.02 2.62 0 3.89-.26 12.57-.26 25.16-.91 37.72-.3 5.83-2.99 11.17-6.62 15.79-8.12 10.34-18.86 14.78-31.97 14.53-15.15-.29-30.3-.07-45.81-.07-1.11 9.24-2.12 18.37-3.32 27.48-1.22 9.27-2.56 18.53-4 27.76-.91 5.84-1.35 11.91-3.32 17.4-4.5 12.55-13.89 20.39-27.01 22.16-9.89 1.33-20.04.83-30.08.98-8.91.13-17.83.27-26.73-.05-6.88-.25-12.92-3.21-18.25-7.51-6.42-5.18-10.65-11.66-12.9-19.75-1.64-5.87-1.09-11.49-.33-17.22.87-6.54 1.91-13.06 2.88-19.59 1.28-8.59 2.63-17.17 3.83-25.77.95-6.83 1.66-13.69 2.57-20.53.78-5.91 1.64-11.8 2.52-17.69.98-6.55 2.06-13.09 3.04-19.64 1.35-8.98 2.44-17.99 4.03-26.93 1.29-7.26.16-11.87-4.89-14.87-1.71-1.01-3.87-1.71-5.84-1.74-6.05-.09-9.68-3.46-12.45-8.29V119.8Zm191.62 47.91c-6.67 0-12.79-.09-18.91.08-1.17.03-3.05.85-3.34 1.72-1.59 4.73-3.54 9.53-3.94 14.43-.89 10.62 3.75 19.54 10.97 26.93 1.98 2.02 4.31 5.41 8.64 3.01 4.59-2.54 6.35-6.61 6.48-11.19.32-11.54.11-23.09.11-34.99Zm-47.17.21c-9.49.18-16.28 3.57-20.78 10.58-5.95 9.27-5.52 18.67.52 27.27 5.25 7.48 13.35 11.6 25.62 9.39-8.81-14.86-10.98-30.49-5.35-47.23ZM0 55.92c2.89-5.55 7.38-8.01 13.71-8.1 4.99-.07 9.51-5.98 10.24-12.55.92-8.24 5.54-11.07 12.26-11.21 12.75-.26 25.51-.07 38.61-.07-3.18 9.81-6.24 19.47-9.45 29.09-4.42 13.2-9.1 26.32-13.32 39.58-.89 2.81-2.12 3.24-4.61 3.23-15.81-.08-31.63-.04-47.44-.04V55.93ZM360.91 24.06V12.02c.02-6.63 4.41-11.5 10.77-11.99 6.02-.46 11.46 3.81 12.33 10.01.63 4.51.84 9.08 1.23 13.57 4.22.36 8.57.34 12.76 1.18 7.7 1.54 10.64 4.75 10.73 12.36.22 19.4.07 38.81.07 58.45H124.25c7.95-23.87 15.84-47.57 23.82-71.55h212.84ZM76.26 95.84c8.07-24.23 15.96-47.92 23.89-71.74h22.53c-3.14 9.69-6.2 19.35-9.4 28.96-4.43 13.34-9.02 26.63-13.41 39.98-.67 2.04-1.5 2.91-3.75 2.85-6.37-.15-12.74-.05-19.87-.05Z" />
        </g>
    </svg>
)
export default WeaponIcon
