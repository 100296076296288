import React from "react";

const GamerPrizes = () => {
    return (
        <div className="black-bg pt-5">
            <div className="container">
                <div className="row">
                    <iframe
                        id="JotFormIFrame-232597908281164"
                        title="Prizes for One Tap"
                        // onLoad="window.parent.scrollTo(0,0)"
                        allowTransparency={true}
                        allowFullScreen={true}
                        allow="geolocation; microphone; camera"
                        src="https://form.jotform.com/232625959406161"
                        style={{
                            minWidth: "100%",
                            maxWidth: "100%",
                            height: 1439,
                            border: "none",
                        }}
                        scrolling="no"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default GamerPrizes;
