import React, { useEffect } from 'react';
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import Footer from '../components/Footer';
import RegisterForm from "../components/RegisterForm";

const JoinTournament = () => {
    const { sphereOneWalletProps, profileInformation, filteredWallets, isSphereOneConnected } = useOneTapProvider();

    const registerUser = (sphereOneWalletProps.username === undefined && isSphereOneConnected);
    const username = `${sphereOneWalletProps.username || profileInformation.username}`;
    const email = `${sphereOneWalletProps.profile?.email || profileInformation.email}`;
    const URL = `https://cdn.jotfor.ms/240305404599153?Onetapid=${username}&email=${email}`;

    const removeForm = () => {
        window.location.href = "/";
    }

    useEffect(() => {
        localStorage.removeItem('unauthorizedPath');
    })

    return (
        <div className="black-bg pt-5">
            {registerUser && <RegisterForm sphereoneEmail={profileInformation?.email} sphereOneId={profileInformation?.uid} isSphereone={true} walletAddress={filteredWallets?.filter(wallet => wallet.isImported === false)[0]?.address} otidParam={null} removeForm={removeForm}></RegisterForm>}
            <div className="section">
                <div className="container">
                    <div className="row">
                        <iframe
                            id="JotFormIFrame-233257319177158"
                            title="Prizes for One Tap"
                            onLoad={() => window.parent.scrollTo(0, 0)}
                            allowtransparency={true}
                            allowFullScreen={true}
                            allow="geolocation; microphone; camera"
                            src={URL}
                            frameBorder="0"
                            style={{
                                width: "100%",
                                height: "calc(100vh - 200px)",
                                border: "none",
                            }}
                            scrolling="no"
                        ></iframe>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>

    );
};

export default JoinTournament;