import React from 'react';
import onetap from '../assets/img/onetap.jpg'
import treasure from '../assets/img/Treasure_Chest_Shine.png'
import SkinRarityTable from '../assets/img/SkinRarityTable.png'
import SpaceSkin from '../assets/img/SpaceSkin.png'
import SpaceSkinAnimation from '../assets/img/SpaceSkinAnimation.gif'
import SpaceMap from '../assets/img/Home Image - Space Map 02.webp'

const Treasure = () => {
    return (
        <div className="black-bg pt-5">
            <div className="container pb-5 pt-5">
                <h2 className="gradient mb-5">One Tap Announces Loot Box</h2>
                <div className='text-white text-left leading-8'>
                    <div className='md:grid grid-cols-2 items-center'>
                        <div>
                            <p> One Tap by Qorbi World is finally announcing the release of their Loot Boxes. The Loot Boxes will have weapon skins and, for the lucky ones, a PRIMAL skin that has the best levels of rarity in the collection.</p>
                            <p> This ready to play, already released web3 FPS game with 1v1 arena fighting action has been growing significantly. One Tap has been organizing community events with cash prizes of over $20,000. To celebrate the first skin drop series, the One Tap team will be holding a $100,000 leaderboard style tournament that will only be open to the skin holders.</p>
                            <p> IMPORTANT DATES: Loot Boxes will be available to open on December 25, 2023 | Skins will be available in the game on January 5, 2024 </p>
                            <p style={{ fontSize: '9px' }}> Dates are subject to change. </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <img src={treasure} className='w-80' alt="lootbox" />
                            <a
                                href='/shop'
                                style={{
                                    backgroundColor: "#BA246E",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "10px 20px",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                }}
                            >
                                Buy Now
                            </a>
                            <button
                                onClick={() => window.open('https://more.playonetap.com/one-tap-loot-box/loot-box-tournament', '_blank')}
                                style={{
                                    backgroundColor: "#BA246E",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "10px 20px",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                }}
                            >
                                Tournament Details
                            </button>
                        </div>
                    </div>
                    <h3 className="gradient mb-5" style={{ marginTop: '100px' }}>One Tap Skin Rarity</h3>
                    <p>
                        When it comes to PRIMAL they are extremely rare. There is only 50 of each weapon as a primal skin. This means that for the whole 190,000 Treasure boxes there are only 500 PRIMAL skins.
                    </p>
                    <p>
                        Each skin purchased will be $10.
                    </p>
                    <p>
                        On December the 21st all Treasure boxes will be opened to reveal their content. On the same date, all weapons and skins can be used in the One Tap FPS game.
                    </p>
                    <img src={SkinRarityTable} className='mt-5 mb-5' alt="SkinRarityTable" />
                    <h2 className='text-2xl mb-5 mt-5' style={{ color: '#42c0fb', textDecoration: 'underline', fontSize: '2.5rem', marginTop: '150px' }}>
                        Alien Assasin!
                    </h2>
                    <p>
                        Then, there will be an airdrop of a special limited-edition skin for those who hold 10 Common, 5 Rare, or 2 Legendary Loot Boxes. This is called ‘Alien Assassin” and it will be revealed soon. This will be the only time this skin will be minted, and once in a lifetime chance to get this rare collectable.
                    </p>
                    <div className='md:grid grid-cols-2 items-center'>
                        <img src={SpaceSkin} className='w-120' alt="alien_assasin" />
                        <img src={SpaceSkinAnimation} className='w-120' alt="alien_assasin" />
                    </div>
                    <h2 className='text-2xl mb-5 mt-5' style={{ color: '#42c0fb', textDecoration: 'underline', fontSize: '2.5rem', marginTop: '150px' }}>
                        Space Map
                    </h2>
                    <p>
                        Ssshhhh, don’t tell anyone but we have a new map coming out. But only One Tap asset holders will be able to play on this map. You must own a skin or pet to have this map available in your game. This incredible new map is part of our outer space collection, where the space station is under attack by alien spiders. This map is full of scary corners, trampolines, and zero gravity pockets. A guaranteed winner for our gamers who love arena battles.
                    </p>
                    <img src={SpaceMap} className='w-120' alt="space_map" />
                    <h3 className="gradient mb-5" style={{ marginTop: '100px' }}>What is One Tap</h3>
                    <p>One Tap is a 1 vs 1 FPS game that combines the high-octane action of traditional shooter games with a unique strategic component. Players can choose from a variety of in-game assets that can significantly influence the course of the game, adding a layer of strategy and depth to the gameplay.</p>
                    <p>The game's unique blend of action and strategy sets it apart in the crowded FPS market. It offers a fresh and exciting challenge for gamers looking for a more strategic and thoughtful approach to the FPS genre.</p>
                    <a href="/download">Play it today at playonetap.com/download</a>
                    <img src={onetap} className='mt-5 mb-5' alt="onetap" />
                </div>
            </div>

        </div>
    );
};

export default Treasure;