import * as React from "react"
import { SVGProps } from "react"
const SnakeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 194.908 204.532"
        width={props.width ? props.width : 25}
        height={props.height ? props.height : 25}
    {...props}
  >
    <g fill="#b7b7b7" data-name="Grupo 17161">
      <path
        d="M79 100.982c-7.93 1.3-12.67.2-19.74-3.46 1 5.64 10.84 35.74 20.45 52a168.361 168.361 0 0 1-.71-48.54Z"
        data-name="Trazado 70721"
      />
      <path
        d="M116 100.982a168.611 168.611 0 0 1-.7 48.5c9.6-16.22 19.45-46.32 20.44-52-7.11 3.7-11.85 4.8-19.74 3.5Z"
        data-name="Trazado 70722"
      />
      <path
        d="M191.32 46.822c-3.33-9.15-11.41-15.45-18.11-22.27-4.33 9-10.62 22.94-19.17 27.72-5.49 3.07-14.86 4.49-21.19 3.69 1.82-8.31 15.94-31.6 22.52-34.91a18.87 18.87 0 0 1 4 13.07c5.86-4 18.17-23.4 9-28a55.209 55.209 0 0 0-25.81-6.12c-16.21.39-30.11 7.61-43.7 15.2a2.406 2.406 0 0 1-2.83 0C82.44 7.612 68.54.392 52.33.002a55.209 55.209 0 0 0-25.81 6.12c-9.14 4.58 3.17 23.95 9 28a18.87 18.87 0 0 1 4-13.07c6.59 3.31 20.7 26.6 22.53 34.91-6.34.8-15.71-.62-21.2-3.69-8.53-4.78-14.85-18.74-19.15-27.72-6.7 6.82-14.78 13.12-18.11 22.27-4.07 11.8-4.89 18.42-1.37 30.23 2 6.86 18.66 14.77 24.63 20.07 5.06 3.58 10.35 7.52 13.79 12.65 12.25 25.58 27.68 45.67 33.25 73.67 2.41 10.19 11.78 21.09 23.57 21.09s21.15-10.88 23.54-21.09c5.57-28 21-48.11 33.25-73.69 3.44-5.13 8.73-9.07 13.79-12.65 6-5.3 22.59-13.21 24.64-20.07 3.53-11.79 2.71-18.41-1.36-30.21Zm-65.84 17.37-11.83 5.81c6.22-10.62 8.62-8.56 11.83-5.81Zm-44.22 5.81-11.83-5.81c3.21-2.75 5.62-4.81 11.83 5.81Zm98.44-9.11c-11.37 5.91-29.12 9.08-33 21.75-9.62 31.27-8.05 32.94-34.48 85.59-3.58 7.13-.35 14.34-9.09 17.84a12.44 12.44 0 0 1-11.31 0c-8.73-3.5-5.51-10.71-9.09-17.84-26.43-52.65-24.85-54.32-34.47-85.59-3.9-12.67-21.65-15.84-33-21.75-5.14-2.45-6.26-8.55-5.81-10.45 2.74 5.9 8.38 6.91 12 8.26 10.68 4 15.1 5.48 24.88 10.81 6.86 3.75 6.5 11.1 8.4 17.16 3.79 7.34 16 10.39 23.52 10.3 8.5-.11 11.62-4.83 19.24-4.83s10.73 4.72 19.24 4.83c7.56.09 19.72-3 23.51-10.3 1.9-6.06 1.54-13.41 8.41-17.16 9.77-5.33 14.19-6.76 24.88-10.81 3.58-1.35 9.22-2.36 12-8.26.39 1.9-.74 8-5.83 10.45Z"
        data-name="Trazado 70723"
      />
    </g>
  </svg>
)
export default SnakeIcon
