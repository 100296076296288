import * as React from "react"
import { SVGProps } from "react"
const quickHammerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 2"
    viewBox="0 0 319.91 97.91"
    width={props.width ? props.width : 25}
        height={props.height ? props.height : 25}
    {...props}
  >
    <path
      d="M165.63 52.85c-.04 4.86-.31 9.73-.07 14.58.4 8.31-7.21 16.58-16.75 16.77-10.47.21-20.96.18-31.43-.1-2.03-.05-4.17-1.53-5.96-2.78-1.35-.95-2.21-2.59-3.31-3.92-1.6-1.95-3.06-5.29-4.87-5.49-2.38-.26-5.04 1.91-7.56 3.09-4.46 2.09-8.83 4.37-13.37 6.27-3 1.26-6.48 1.99-9.22-2.16-1.81-2.74-3.85-5.33-5.75-8.02-1.26-1.78-2.63-2.11-4.65-1.02-14.4 7.79-28.85 15.48-43.28 23.22-2.23 1.2-4.37 2.57-6.64 3.67-5.15 2.49-9.78-.13-10.46-5.83-.03-.25 0-.5 0-.75-.24-11.67-.36-23.34-.76-35C1.23 46.01.64 36.65.04 27.29c-.37-5.75.87-8.2 6.56-9.58 8.44-2.05 16.94-4.1 25.53-5.16 9.28-1.14 18.7-1.59 28.05-1.51 7.68.07 15.35 1.41 23.04 2.03 1.36.11 2.96-.18 4.14-.84 9.11-5.12 18.83-8.75 29.11-10.18C125.6.78 134.88.11 144.09.08 199.48-.07 254.86.02 310.23.02c7.7 0 9.66 1.93 9.66 9.54 0 5.61.02 11.23 0 16.84-.03 5.71-2.32 8.03-8.14 8.04-19.08.04-38.17.09-57.25-.03-5.68-.03-9.7 2.15-11.94 7.39-.37.86-.59 1.83-.67 2.77-.44 5.29-2.87 7.55-8.29 7.55h-63.23c-1.37 0-2.74.07-4.11.11-.21.2-.42.41-.63.61Zm-38.42-.48c-9.02-.76-12.83 1.16-14.9 7.43-.22.68-.42 1.71-.08 2.18 1.96 2.66 3.97 5.29 6.2 7.72.82.89 2.25 1.69 3.42 1.71 8.6.14 17.2.26 25.79-.07 1.75-.07 4.75-1.91 4.9-3.19.58-4.99.23-10.09.23-15.07h-12.58c-.41 8.59-1.54 10.25-6.87 10.27-4.83.02-5.7-1.51-6.11-10.96Z"
      data-name="Capa 1"
      style={{
        fill: "#b7b7b7",
    }}
    />
  </svg>
)
export default quickHammerIcon
