import * as React from "react";
const Transfer = (props) => (
  <svg
    width="24.592"
    height="24.592"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{"transfer_fill"}</title>
    <g
      id="\u9875\u9762-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Arrow"
        transform="translate(-912.000000, -50.000000)"
        fillRule="nonzero"
      >
        <g id="transfer_fill" transform="translate(912.000000, 50.000000)">
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
            id="MingCute"
            fillRule="nonzero"
          />
          <path
            d="M20,14 C20.8284,14 21.5,14.6716 21.5,15.5 C21.5,16.2796706 20.9050879,16.9204457 20.1444558,16.9931332 L20,17 L7.62132,17 L9.06066,18.4393 C9.64645,19.0251 9.64645,19.9749 9.06066,20.5607 C8.51148188,21.1097938 7.64242582,21.1441121 7.05326295,20.6636551 L6.93934,20.5607 L3.11005,16.7314 C2.13569833,15.757 2.77030114,14.1140984 4.10128528,14.0056543 L4.24142,14 L20,14 Z M14.9393,3.4393425 C15.4884875,2.89016438 16.3575969,2.85584074 16.9467746,3.3363716 L17.0607,3.4393425 L20.8899,7.2686325 C21.8643,8.24298417 21.2296801,9.88590046 19.8987309,9.9943456 L19.7586,10 L4,10 C3.17157,10 2.5,9.3284325 2.5,8.5 C2.5,7.72030353 3.09488554,7.07955128 3.85553954,7.00686657 L4,7 L16.3787,7 L14.9393,5.5606625 C14.3536,4.9748725 14.3536,4.0251325 14.9393,3.4393425 Z"
            id="\u5F62\u72B6"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Transfer;