import React from 'react';
import { useParams } from 'react-router-dom';
import treasure from '../assets/img/Treasure_Chest_Shine.png'
import dog from '../assets/img/dog.png'

const Thankyou = () => {
    const params = useParams()
    return (
        <div className="black-bg pt-5">
            <div className="container pb-5 pt-5">
                <h2 className="gradient mb-5">{params.status}</h2>
                <div className='text-white text-left'>
                    <div className='bg-box' style={{ width: "50%", margin: "0 auto", maxWidth: "100%" }}>
                        <div className='bg-cover bg-center m-auto rounded-lg' style={{ backgroundImage: `url(${params.code === '2323' ? treasure : dog})` }} />
                        <h2 className='gradient mb-3 mt-5' style={{ fontSize: 16 }}>{params.code === '2323' ? "One Tap Treasure Box" : "Dog (Pet)"}</h2>
                        <p className='text-white mb-5' >{params.code === '2323' ? "Each One Tap Treasure box has a mystery item inside. Each item is an in-game skin used to add more fun to your gameplay." : "The One Tap Dog is a great friend to take into a new round. The dog gives you an ability to put up a turret which will prevent your opponent from flanking your position. There are only 1500 Dogs available."}</p>
                        <span className='text-custom-red2'>{params.code === '2323' ? "$10.00" : "$55.00"}</span>
                        <p className='text-white text-sm text-right'>{params.status === "success" ? "your items has been added to you in-game wallet." : "Something went wrong. Please try again"}</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Thankyou;