import React, { useEffect, useState } from "react";
import { useOneTapProvider } from "../utils/Globals/CustomHooks";
import { passportInstance } from "../utils/Globals/PassportService";
import { useNavigate } from "react-router-dom";
import { Link } from "@imtbl/imx-sdk";
import axios from "axios";
import IMXLogo from "../assets/svg/ImmutableLogo";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import WombatLogo from "../assets/img/wombat-logo-min.png";
import RegisterWalletOverlay from '../components/RegisterWalletOverlay';
import RegisterForm from "../components/RegisterForm";
import PassportLogo from "../assets/img/passport-logo.png"

const Login = () => {

  const [message, setMessage] = useState({ text: null, links: [] });
  const [registerUser, setRegisterUser] = useState(false);
  const [wallet, setWallet] = useState("");
  const [registerWallet, setRegisterWallet] = useState({ show: false, wallet: "" });

  const { setLoginMethod, loginMethod, setIsEthereumConnected, setIsWalletConnected, setFilteredWallets, setProfileInformation } = useOneTapProvider();
  const navigate = useNavigate();

  const registerNewUser = async (address, email = 'placeholder', isPassport = false) => {
    const searchValue = isPassport === true ? email : address;
    try {
      const userResponse = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${searchValue}`);
      const defaultProfilePicture = `https://ui-avatars.com/api/?background=random&length=1&name=${userResponse.data.username}`; //Image Generated from https://ui-avatars.com/#google_vignette
      const updatedUserInfo = {
        ...userResponse.data,
        profilePicture: defaultProfilePicture,
      };
      setProfileInformation(updatedUserInfo);
      setLoginMethod('ETH')
      setIsEthereumConnected(true)
      setIsWalletConnected(true)
      setFilteredWallets([address])
      localStorage.setItem('profileInformation', JSON.stringify(updatedUserInfo));
      localStorage.setItem('loginMethod', 'ETH');
      localStorage.setItem('isEthereumConnected', JSON.stringify(true));
      localStorage.setItem('isWalletConnected', JSON.stringify(true));
      localStorage.setItem('filteredWallets', JSON.stringify([address]));
    } catch (err) {
      setRegisterUser(true);
    }
  }

  const metamaskLogin = async () => {
    try {
      const providerMetamask = window.ethereum;
      if (providerMetamask) {
        const accounts = await providerMetamask.request({
          method: "eth_requestAccounts",
        })
        const address = accounts[0];
        return { success: true, data: address };
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error.message);
    }
  };

  const validateWallet = async (address) => {
    try {
      const immutableResponse = await axios.get(`https://api.x.immutable.com/v1/users/${address}`);
      if (immutableResponse.data) {
        return true
      }
    } catch (immutableError) {
      // Here, the user needs to register into IMX
      return false
    }
  };

  const connect_eth_provider = async () => {
    try {
      if ("ethereum" in window) {
        const userAddress = await metamaskLogin();
        if (userAddress.data) {
          const isIMX = await validateWallet(userAddress.data)
          if (isIMX) {
            registerNewUser(userAddress.data)
            setWallet(userAddress.data)
          } else {
            setRegisterWallet({ show: true, wallet: userAddress.data })
          }
        }
      } else {
        setMessage({ text: "You need to install the Metamask or Wombat extention", links: [["Metamask", "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=google.com"], ["Wombat", "https://chromewebstore.google.com/detail/wombat-gaming-wallet-for/amkmjjmmflddogmhpjloimipbofnfjih?utm_source=google.com"]] })
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connectImmutable = async (source) => {
    if (localStorage.getItem("WALLET_ADDRESS")) {
      localStorage.removeItem("STARK_PUBLIC_KEY");
      localStorage.removeItem("WALLET_ADDRESS");
    }
    try {
      const link = new Link("https://link.x.immutable.com");
      if ("ethereum" in window) {
        const { address, starkPublicKey } = await link.setup({
          providerPreference: undefined,
        });
        if (address && starkPublicKey) {
          setWallet(address)
          setRegisterWallet({ show: false, wallet: "" })
          if (source === "register") {
            window.location.reload();
          } else {
            registerNewUser(address)
          }
        }
      } else {
        setMessage({ text: "You need to install Metamask or Wombat extention", links: [["Metamask", "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=google.com"], ["Wombat", "https://chromewebstore.google.com/detail/wombat-gaming-wallet-for/amkmjjmmflddogmhpjloimipbofnfjih?utm_source=google.com"]] })
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const loginPassport = async () => {
    try {
      const provider = passportInstance.connectEvm();
      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      setWallet(accounts[0])
      const userProfile = await passportInstance.getUserInfo();
      registerNewUser(accounts[0], userProfile.email, true)
      try {
        const userResponse = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${userProfile.email}`);
        console.log(userResponse.status);
      } catch (sphereOneError) {
        setRegisterUser(true);
      }
    } catch (error) {
      console.error(error);
    }
    // window.location.reload();
  };
  const showMessage = (message) => {
    setMessage({ text: message, links: [] })
  }
  const removeForm = () => {
    setRegisterUser(false);
  }
  useEffect(() => {
    passportInstance.loginCallback();
    if (loginMethod !== "Null") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginMethod]);

  return (
    <>
      <div className="section pets-container" style={{ padding: "10%" }}>
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h2 className="txt-white">Login And Join The Fun</h2>
          <div className="signin-buttons" >
            <button
              className="style2"
              style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#14b3cb" }}
              onClick={() => { loginPassport() }}
            >
              <img src={PassportLogo} alt="Wombat Logo" width={25} height={25} /> Immutable Passport
            </button>
            <h2 className="txt-white" style={{ fontSize: 30 }}>Or connect using your Favorite wallet</h2>
            <button onClick={connect_eth_provider} style={{ backgroundColor: "#eb8022", marginTop: 30 }}>
              <MetamaskLogo /> Connect with Metamask
            </button>
            <button onClick={connect_eth_provider} style={{ backgroundColor: "#FF4F24" }}>
              <img src={WombatLogo} alt="Wombat Logo" width={25} height={25} />Connect with Wombat
            </button>
            <button onClick={() => connectImmutable("btn")} style={{ backgroundColor: "#14b3cb" }}>
              <IMXLogo /> Connect with ImmutableX
            </button>
          </div>

        </div>
        {registerWallet.show && <RegisterWalletOverlay registerImmutable={connectImmutable} unregisteredWallet={registerWallet.wallet}></RegisterWalletOverlay>}
        {registerUser && <RegisterForm isSphereone={false} walletAddress={wallet} otidParam={null} showMessage={showMessage} removeForm={removeForm}></RegisterForm>}
        {message.text && (
          <div className="message-container" style={{ marginTop: 25 }}>
            <p>{message.text}</p>
            {message.links && message.links.length > 0 && (
              <div>
                {message.links.map((link, index) => (
                  <a
                    key={index}
                    href={link[1]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: 10, textDecoration: 'none', color: 'white' }}
                  >
                    <button className="style2">
                      {link[0]}
                    </button>
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
