import * as React from "react"
import { SVGProps } from "react"
const WidgetIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 20}
        height={props.height ? props.height : 20}
        viewBox="0 0 243.151 243.303"
        data-name="Grupo 162"
        {...props}
    >
        <path
            d="M226.007 17.509C213.6 5.107 197.22 0 170.227 0H72.954c-26.993 0-43.408 5.107-55.81 17.509S0 45.596 0 73.319v96.665c0 27.723 4.742 43.408 17.144 55.81s28.817 17.509 55.81 17.509h97.273c26.993 0 43.377-5.107 55.78-17.509s17.144-28.087 17.144-55.81V73.319c0-27.723-4.742-43.408-17.144-55.81Zm-43.773 153.934c0 14.226-4.742 18.239-21.886 18.239H145.97l-24.318 33.437-24.319-33.438H82.682c-17.144 0-21.886-4.012-21.886-18.239V71.86c0-14.226 4.742-18.239 21.886-18.239h77.666c17.509 0 21.886 3.648 21.886 18.239Z"
            data-name="Trazado 130-2"
        />
        <path d="m121.53 146.943 24.591-33.681H96.938Z" data-name="Trazado 131-2" />
    </svg>
)
export default WidgetIcon
