import { passport, config } from "@imtbl/sdk";

// export const passportInstance = new passport.Passport({
//     baseConfig: {
//         environment: config.Environment.SANDBOX,
//     },
//     clientId: '0zVBU9Biei3EFItHcLafzyfHxvduJ8LQ',
//     redirectUri: 'http://localhost:3000',
//     logoutRedirectUri: 'http://localhost:3000',
//     audience: 'platform_api',
//     scope: 'openid offline_access email transact',
// });
export const passportInstance = new passport.Passport({
    baseConfig: {
        environment: config.Environment.PRODUCTION,
    },
    clientId: 'pGvnlPTHoBui5e8g36pg6VaaVvzip1fo',
    redirectUri: 'https://playonetap.com/signin',
    logoutRedirectUri: 'https://playonetap.com/signin',
    audience: 'platform_api',
    scope: 'openid offline_access email transact',
});