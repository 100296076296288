import WebSDK, { LoginBehavior } from "websdk";

// eslint-disable-next-line no-restricted-globals
const url = location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://playonetap.com';
const clientId = "cefdabae-8803-4e8e-91e6-b2b0b26d1d5a";
const redirectUri = url;
// const apiKey = "ddc0b6e9-2259-4da4-a8d1-b8010a50e398";
const apiKey = "312a907b-472f-431f-91dd-91ea9b923b0b";

export const sphereoneSdk = new WebSDK(clientId, redirectUri, apiKey, LoginBehavior.REDIRECT);
