type BtnProps = {
    label: string,
    action: () => void,
    style: "style1" | "style2"
}

const Btn = ({ label, action, style }: BtnProps) => {
    return (
        <button className={`flex items-center justify-center ${style}`} onClick={action}>
            {label}
        </button>
    );
};

export default Btn;