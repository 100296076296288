import React, { useState } from 'react';
import bg from '../assets/img/download.png'
import logoElixir from '../assets/img/logoElixir.png'
import logoHyperPlay from '../assets/img/HyperPlayCustomBadge.png'
import Footer from '../components/Footer';

const Download = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    return (
        <>
        <div className="banner relative section">
            <div className="container">
                <div className="lg:absolute left-0 w-full text-left pl-28 text-container" style={{ textAlign: isMobile ? "center" : undefined }}>
                    <h2 className="txt-white">It's time to</h2>
                    <h2 className="gradient mb-5">retaliate!</h2>
                    <h4 className='text-sm text-white leading-10 text-center lg:text-left'>One Tap is a strategic First Person Shooter (FPS) game <br />with in-game assets that can turn the tide of battle.</h4>
                    <div className="md:flex lg:justify-start justify-center mt-5" style={{ flexDirection: "column", margin: isMobile ? "auto" : undefined }}>
                        <div style={{ display: "flex", marginBottom: "10px", marginLeft: "5px" }}>
                            <button
                                style={{
                                    marginRight: 15,
                                    width: 250,
                                    background: isHovered1 ? "-webkit-linear-gradient(258deg, #9200b7, #d73d3b)" : "rgba(146, 0, 183, 0.5)",
                                    transition: "background-color 0.5s"
                                }}
                                onMouseEnter={() => setIsHovered1(true)}
                                onMouseLeave={() => setIsHovered1(false)}
                                className={`flex items-center justify-center style2`}
                                onClick={() => window.open("https://store.hyperplay.xyz/game/onetap", "_blank")}
                            >
                                <img src={logoHyperPlay} alt="elixir" style={{ marginLeft: "5px", alignSelf: "center" }} />
                            </button>   
                            <button
                                style={{
                                    width: 250,
                                    background: isHovered2 ? "-webkit-linear-gradient(258deg, #9200b7, #d73d3b)" : "rgba(146, 0, 183, 0.5)",
                                    transition: "background-color 0.5s"
                                }}
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                                className={`flex items-center justify-center style2`}
                                onClick={() => window.open("https://launcher.elixir.app/browse/one-tap", "_blank")}
                            >
                                <img src={logoElixir} alt="elixir" style={{ marginLeft: "5px", height: "60%", alignSelf: "center" }} />
                            </button>
                        </div>
                        <p style={{ color: "white", marginTop: 10, marginBottom: 10, }}>Or try</p>
                        <button
                            style={{ width: 250, marginTop: 15, backgroundColor: "#CD344D", border: "none", color: "white", textAlign: "center", textDecoration: "none", display: "inline-block", fontSize: "16px", borderRadius: "12px", cursor: "pointer", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
                            className="style2"
                            onClick={() => { window.location.href = "/mobile-registration"; }}
                        >
                            Join Mobile waitlist
                        </button>
                    </div>
                    <div style={{ textAlign: isMobile ? "center" : undefined }}>
                        <h2 className="txt-white" style={{ fontSize: isMobile ? "24px" : undefined, marginTop: 30 }}>
                            UPCOMING
                        </h2>
                        <h2 className="gradient" style={{ fontSize: isMobile ? "20px" : undefined }}>
                            PLAY TO EARN
                        </h2>
                        {/* <button
                            style={{ marginTop: 15, backgroundColor: "#CD344D", border: "none", color: "white", textAlign: "center", textDecoration: "none", display: "inline-block", fontSize: "16px", borderRadius: "12px", cursor: "pointer", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
                            className="style2"
                            onClick={() => { window.location.href = "https://qorbiworld.com/qorb-collection"; }}
                        >
                            GET WHITELISTED
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="bg-center bg-cover banner-bg lg:absolute" style={{ backgroundImage: `url(${bg})` }} />
        </div >
        <Footer/>
        </>
    );
};

export default Download;