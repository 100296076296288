// LeftMenu.js
import React from 'react';
// import { NavLink, useLocation } from 'react-router-dom';

const LeftMenu = () => {
    // const [showLootBoxSubMenu, setShowLootBoxSubMenu] = useState(false);
    // const [showFAQsSubMenu, setShowFAQsSubMenu] = useState(false);
    // const location = useLocation();

    // useEffect(() => {
    //     // Check if the current page is within the One Tap Loot Box section
    //     setShowLootBoxSubMenu(location.pathname.startsWith('/more/OneTapLootBox'));
    //     // Check if the current page is within the FAQs section
    //     setShowFAQsSubMenu(location.pathname.startsWith('/more/FAQs'));
    // }, [location]);

    return (
        <div className="left-menu">
            <ul style={{ position: "fixed" }}>
                {/* <li>
                    <NavLink exact="true" to="/more/GameUpdates" activeclassname="active">
                        Game Updates
                    </NavLink>
                </li>
                <li>
                    <NavLink exact="true" to="/more/GameSupport" activeclassname="active">
                        Game Support
                    </NavLink>
                </li>
                <li>
                    <NavLink exact="true" to="/more/SocialAccounts" activeclassname="active">
                        Social Accounts
                    </NavLink>
                </li>
                <li>
                    <NavLink exact="true" to="/more/AboutOneTapFPSGame" activeclassname="active">
                        About One Tap FPS Game
                    </NavLink>
                </li>
                <li>
                    <NavLink exact="true" to="/more/AboutQorbiWorldPets" activeclassname="active">
                        About Qorbi World Pets
                    </NavLink>
                </li>
                <li>
                    <NavLink exact="true" to="/more/OneTapLootBox" activeclassname="active">
                        One Tap Loot Box
                    </NavLink>
                    {showLootBoxSubMenu && (
                        <ul>
                            <li>
                                <NavLink exact="true" to="/more/OneTapLootBox-LootBoxTournament" activeclassname="active">
                                    Loot Box Tournament
                                </NavLink>
                                <ul>
                                    <li>
                                        <NavLink exact="true" to="/more/OneTapLootBox-TournamentOfficialRules" activeclassname="active">
                                            Tournament Official Rules
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    )}
                </li>
                <li>
                    <NavLink exact="true" to="/more/FAQs" activeclassname="active">
                        FAQs
                    </NavLink>
                    {showFAQsSubMenu && (
                        <ul>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-SphereOneWallet" activeclassname="active">
                                    Sphere One Wallet
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-AddingAFriend" activeclassname="active">
                                    Adding a friend
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-HowToUseYourPet" activeclassname="active">
                                    How to use your pet
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-CreatingAPrivateLobby" activeclassname="active">
                                    Creating a private lobby
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-TradeMyEggsForAPet" activeclassname="active">
                                    Trade my eggs for a pet
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-WhereDoYouGetPets" activeclassname="active">
                                    Where do you get pets
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact="true" to="/more/FAQs-WeaponSkins" activeclassname="active">
                                    Weapon Skins
                                </NavLink>
                            </li>
                        </ul>
                    )}
                </li> 
                <li>
                    <NavLink exact="true" to="/more/Terms" activeclassname="active">
                        Terms
                    </NavLink>
                </li>
                <li>
                    <NavLink exact="true" to="/more/Privacy" activeclassname="active">
                        Privacy
                    </NavLink>
                </li>
                */}
            </ul>
        </div>
    );
};

export default LeftMenu;
