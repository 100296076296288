/* eslint-disable no-restricted-globals */
import axios from "axios";

export const getData = async (endpoint: string) => {

    try {
        let respond = await axios.get(endpoint)
        return { success: true, data: respond.data }
    } catch (error) {
        return { success: false, data: error }
    }
}