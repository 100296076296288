import * as React from "react"
import { SVGProps } from "react"
const MonkeyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 2"
    viewBox="0 0 436.58 333.63"
    width={props.width ? props.width : 25}
    height={props.height ? props.height : 25}
    {...props}
  >
    <defs>
            <style>{".cls-2{fill:#3f3f3f}"}</style>
        </defs>
    <g data-name="Capa 1">
      <path d="M0 127.91c1.43-4.95 2.38-10.1 4.36-14.82 8.16-19.5 22.21-32.42 42.84-38.21 11.93-3.35 23.64-2.46 35.39.19 3.03.68 5.84 2.32 8.55 3.43 27.8-50 68.84-78.58 126.85-78.5 57.99.08 99.5 28.25 126.93 77.35 8.67-1.67 17.06-4.2 25.58-4.7 9.24-.54 18.48 1.3 27.13 5.15 13.21 5.87 23.76 14.75 30.88 27.42 4.73 8.42 7.21 17.43 7.9 27.18 1.02 14.5-2.61 27.44-10.25 39.67-5.51 8.82-13.93 13.68-22.44 18.68-20.61 12.1-41.38 23.93-61.7 36.49-8.91 5.51-16.87 12.58-25.28 18.91-1.16.88-2.45 1.58-4.72 3.03 2.21-12.56 1.56-23.9-1.61-35.02-3.12-10.97-8.71-20.58-16.42-28.45 3.93-6.3 8.04-12.35 11.6-18.71 4.08-7.28 5.88-15.28 6.97-23.57 2.45-18.59-.75-36.03-10.01-52.23-9.51-16.64-23.93-27.04-42.73-30.67-12.54-2.42-24.92-.62-37.02 3.59-2.74.95-6.35 1.15-9.02.19-10.28-3.69-20.88-5.6-31.59-4.56-18.3 1.77-33.18 10.24-43.94 25.44-8.65 12.21-13.21 25.72-14.58 40.69-1.78 19.37 3.21 36.63 13.84 52.54 1.53 2.29 3.54 4.26 5.47 6.55-16.69 18.09-22.03 39.37-18.83 64.11-5.32-3.73-10.04-6.54-14.19-10.04-13.59-11.47-29.25-19.7-44.36-28.79-14.35-8.63-29.1-16.63-43.23-25.6-10.43-6.61-15.52-17.53-19.72-28.7-1.22-3.25-1.79-6.74-2.67-10.13v-17.93Zm82.86 46.13c6.93 0 11.11-3.08 12.54-8 1.13-3.85-1.22-8.74-5.69-11.64-6.92-4.5-13.83-9.02-20.86-13.35-2.07-1.28-4.43-2.42-6.8-2.79-4.94-.79-9.53 2.55-11.43 7.74-1.26 3.43 1.22 8.94 5.5 11.79 6.43 4.28 12.88 8.54 19.45 12.62 2.72 1.69 5.75 2.89 7.29 3.65Zm302.08-25.79c.33-.13.66-.26 1-.39-1.14-2.24-1.88-4.85-3.51-6.64-3.58-3.91-9.51-4.08-14.45-.93-6.95 4.44-13.89 8.88-20.84 13.32-6.04 3.85-8.09 10.33-4.9 15.53 3.31 5.41 9 6.57 15.5 2.68 7.78-4.65 15.44-9.53 22.99-14.56 3.02-2.01 5.2-4.86 4.22-9.02Z" 
      style={{
        fill: props.color ? props.color : "#191919",
    }}
      />
      <path d="M184.15 79.27c9.09-1.19 17.77 3.19 26.7 6.45 5.34 1.95 10.59 1.99 15.77-.42 10.03-4.67 20.51-7.17 31.59-6.07 10.98 1.09 19.53 6.64 26.12 15.33 9.62 12.69 12.31 27.39 10.95 42.7-1.16 13.02-6.12 24.76-16.66 33.33-1.15.94-3.36 1.56-4.69 1.13-13.75-4.51-27.7-7.77-42.27-7.98-7.61-.11-15.23-.92-22.83-.74-13.91.33-27.56 2.74-40.86 6.83-8.84 2.72-11.57 2.07-16.69-5.8-5.41-8.31-8.69-17.3-9.69-27.38-1.45-14.66 1.67-27.97 9.55-40.19 7.47-11.58 17.9-18.04 33-17.2Zm16.5 53.14c0-1.75-.05-2.75 0-3.74.38-6.38-3.35-10.36-8.54-12.56-4.98-2.11-10.42-2.1-14.98 1.65-6.68 5.49-7.52 19.61-1.9 26.24 4.39 5.18 11.73 7.16 18.34 3.34 6.18-3.57 7.69-9.12 7.07-14.93Zm64.04-.71v-2.55c0-6.29-3.32-11.08-9.31-13.45-5.25-2.08-11.86-.6-15.77 3.61-4.82 5.19-4.82 11.81-3.73 17.88 1.55 8.54 9.75 14.52 18.88 11.3 6.92-2.44 9.93-7.39 9.93-16.78ZM132.38 277.81h172.1c-13.24 31.33-48.32 58-92.26 55.68-36.31-1.92-67.77-26.15-79.84-55.68ZM288.64 249.96H148c-2.27-13.34-2.13-26.42 4.86-38.63 6.63-11.58 17.51-17.2 29.71-21.13 15.7-5.05 31.88-4.92 47.92-4.03 15.14.84 29.96 4.08 42.65 13.45 7.69 5.68 12.75 13.18 15 22.35 2.26 9.2 1.82 18.55.48 27.97Zm-70.52-44.97c-.85-.95-1.54-1.88-2.39-2.62-5.66-4.93-15.66-5.02-20.08.35-5.89 7.16-6.17 12.46-.58 19.81 3.66 4.81 8.43 8.76 12.31 13.42 5.74 6.9 16.51 5.96 22.28.05 4.04-4.15 7.8-8.57 11.83-12.73 5.14-5.3 4.3-14.33 1.58-18.28-3.6-5.23-11.73-7.76-17.83-5.16-2.46 1.05-4.47 3.19-7.12 5.17Z" 
      style={{
        fill: props.color ? props.color : "#191919",
    }}
      />
    </g>
  </svg>
)
export default MonkeyIcon
