import * as React from "react"
import { SVGProps } from "react"
const EgyptIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20" width={props.width ? props.width : 25} height={props.height ? props.height : 20} {...props}>
    <path
      fill="#fff"
      d="M9.956 4.07a3 3 0 0 1 5.088 0l7.087 11.34A3 3 0 0 1 19.587 20H5.413a3 3 0 0 1-2.544-4.59Z"
      data-name="Pol\xEDgono 10"
    />
  </svg>
)
export default EgyptIcon
