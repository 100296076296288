const InstructionsOverlay = ({ closeInstructions }) => {

    const buttonStyle =
    {
        height: "50",
        marginTop: "25px",
        borderRadius: "40px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "white",
        textTransform: "uppercase",
        color: "white",
        padding: "5px",
        marginRight: "10px",
        marginLeft: "10px",
    }


    return (
        <div className="darkbg pt-5 assets">
            <div
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 75,
                }}
            >
                <div
                    style={{
                        background: 'black',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                        width: '75%',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <p style={{ fontSize: '36px', color: 'white', marginTop: "25px" }}>
                            New Wallets:
                        </p>
                        <p style={{ fontSize: '24px', color: 'grey', marginTop: "10px" }}>
                            Upon account creation, we automatically set up an Immutable X in-game wallet for you. We use this wallet to secure our application. You may have logged in with another wallet through our signup system, that wallet will be your authentication account, but it is not the in-game wallet. To see all your wallets in the SphereOne go to
                            {" "}<a href="https://wallet.sphereone.xyz" target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>https://wallet.sphereone.xyz</a>{". "}
                        </p>
                        <p style={{ fontSize: '24px', color: 'grey', marginTop: "10px" }}>
                            The wallet displayed in the bottom right corner is your in-game wallet. All your assets from all your wallets will appear on the My Items page. For your pets and skins to show in the game you will need to have it in your in-game wallet.
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <p style={{ fontSize: '36px', color: 'white', marginRight: 25 }}>
                                Examples:
                            </p>
                            <img
                                src={require('../assets/img/MainWalletExample.png')}
                                alt='Example Popup'
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button
                            onClick={closeInstructions}
                            style={buttonStyle}
                        >
                            Enter Wallet
                        </button>
                        <button
                            onClick={() => window.open("https://qorbiworld.medium.com/one-tap-easy-login-wallet-connection-with-sphereone-0bf4b24e6d35", "_blank")}
                            style={buttonStyle}
                        >
                            Learn About SphereOne
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstructionsOverlay;
