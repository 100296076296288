import React, { useEffect, useState } from "react";
import axios from "axios";
import RegisterWalletOverlay from '../components/RegisterWalletOverlay';
import { Link } from "@imtbl/imx-sdk";
import RegisterForm from "../components/RegisterForm";
import { useParams } from 'react-router-dom';
import IMXLogo from "../assets/svg/ImmutableLogo";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import WombatLogo from "../assets/img/wombat-logo-min.png";

const WombatLogin = () => {

    const [registerWallet, setRegisterWallet] = useState({ show: false, wallet: "" });
    const [registerUser, setRegisterUser] = useState(false);
    const [wallet, setWallet] = useState("");
    const [message, setMessage] = useState({ text: null, links: [] });
    // const [showButtons, setShowButtons] = useState(false);

    const { otid, method } = useParams();

    const showMessage = (message) => {
        setMessage({ text: message, links: [] })
    }

    const metamaskLogin = async () => {
        try {
            const providerMetamask = window.ethereum;
            if (providerMetamask) {
                const accounts = await providerMetamask.request({
                    method: "eth_requestAccounts",
                })
                const address = accounts[0];
                return { success: true, data: address };
            }
        } catch (error) {
            console.error("Error connecting to MetaMask:", error.message);
            if (error.message === "Was told to reject tab answer") {//tab was closed
                // setShowButtons(true)
            }
        }
    };

    const validateWallet = async (address) => {
        try {
            const immutableResponse = await axios.get(`https://api.x.immutable.com/v1/users/${address}`);
            if (immutableResponse.data) {
                return true
            }
        } catch (immutableError) {
            // Here, the user needs to register into IMX
            return false
        }
    };

    const removeForm = () => {
        setRegisterUser(false);
        showMessage("You can now close this window and return to the One Tap game");
    }

    const registerNewUser = async (address) => {
        try {
            const userResponse = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${address}`);
            try {
                const response = await axios.post('https://api.qorbiworld.com/sphere-one/set-wombat-user', {
                    otid: otid,
                    username: userResponse.data.username,
                    walletId: userResponse.data.walletId,
                    email: userResponse.data.walletId,
                });
                console.log(response);
                setMessage({ text: "You can now close this window and return to the One Tap game", links: [] })
            } catch (addUserError) {
                setMessage({ text: "There was an error, please try again", links: [] })
            }
        } catch (sphereOneError) {
            setRegisterUser(true);
        }
    }

    const connectImmutable = async (source) => {
        if (localStorage.getItem("WALLET_ADDRESS")) {
            localStorage.removeItem("STARK_PUBLIC_KEY");
            localStorage.removeItem("WALLET_ADDRESS");
        }
        try {
            const link = new Link("https://link.x.immutable.com");
            if ("ethereum" in window) {
                const { address, starkPublicKey } = await link.setup({
                    providerPreference: undefined,
                });
                if (address && starkPublicKey) {
                    setWallet(address)
                    setRegisterWallet({ show: false, wallet: "" })
                    if (source === "register") {
                        window.location.reload();
                    } else {
                        registerNewUser(address)
                    }
                }
            } else {
                setMessage({ text: "You need to install Metamask or Wombat extention", links: [["Metamask", "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=google.com"], ["Wombat", "https://chromewebstore.google.com/detail/wombat-gaming-wallet-for/amkmjjmmflddogmhpjloimipbofnfjih?utm_source=google.com"]] })
            }
        } catch (error) {
            console.error(error.message);
            if (error.message === "Code 1003 - Link window closed.") {//tab was closed
                // setShowButtons(true)
            }
        }
    };

    const connect_eth_provider = async () => {
        try {
            if ("ethereum" in window) {
                const userAddress = await metamaskLogin();
                if (userAddress.data) {
                    setWallet(userAddress.data)
                    const isIMX = await validateWallet(userAddress.data)
                    if (isIMX) {
                        registerNewUser(userAddress.data)
                    } else {
                        setRegisterWallet({ show: true, wallet: userAddress.data })
                    }
                }
            } else {
                setMessage({ text: "You need to install the Metamask or Wombat extention", links: [["Metamask", "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=google.com"], ["Wombat", "https://chromewebstore.google.com/detail/wombat-gaming-wallet-for/amkmjjmmflddogmhpjloimipbofnfjih?utm_source=google.com"]] })
            }
        } catch (error) {
            console.error(error);
        }
    };

    const logoutWallets = async () => {
        const providerMetamask = await window.ethereum;
        if (providerMetamask) {
            try {
                await window.ethereum.request({
                    "method": "wallet_revokePermissions",
                    "params": [
                        {
                            "eth_accounts": {}
                        }
                    ]
                });
            } catch (error) {
                console.error("Error revoking wallet permissions:", error);
            }
        }
        if (localStorage.getItem("WALLET_ADDRESS")) {
            localStorage.removeItem("STARK_PUBLIC_KEY");
            localStorage.removeItem("WALLET_ADDRESS");
        }
    };

    useEffect(() => {
        logoutWallets();
        if (method === "eth") {
            connect_eth_provider()
        } else if (method === "imx") {
            connectImmutable("btn")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="section pets-container" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 9999, }}>
            <div className="image-container" style={{ marginTop: 100 }}>
                <img
                    className='img-b-shadow'
                    src={require('../assets/img/Logo.png')}
                    alt='Example Shadow'
                    style={{ width: '100%', maxWidth: '33vw', display: 'block', margin: '0 auto' }}
                />
            </div>
            <div className="signin-buttons" >
                <h2 className="txt-white">Login To One Tap</h2>
                <h2 className="txt-white">With You Favorite Wallet</h2>
            </div>
            {true &&
                <div className="signin-buttons" >
                    <button onClick={connect_eth_provider} style={{ backgroundColor: "#eb8022", marginTop: 30 }}>
                        <MetamaskLogo /> Connect with Metamask
                    </button>
                    <button onClick={connect_eth_provider} style={{ backgroundColor: "#FF4F24" }}>
                        <img src={WombatLogo} alt="Wombat Logo" width={25} height={25} />Connect with Wombat
                    </button>
                    <button onClick={() => connectImmutable("btn")} style={{ backgroundColor: "#14b3cb" }}>
                        <IMXLogo /> Connect with ImmutableX
                    </button>
                </div>
            }
            {registerWallet.show && <RegisterWalletOverlay registerImmutable={connectImmutable} unregisteredWallet={registerWallet.wallet}></RegisterWalletOverlay>}
            {registerUser && <RegisterForm isSphereone={false} walletAddress={wallet} otidParam={otid} showMessage={showMessage} removeForm={removeForm}></RegisterForm>}
            {message.text && (
                <div className="message-container" style={{ marginTop: 25 }}>
                    <p>{message.text}</p>
                    {message.links && message.links.length > 0 && (
                        <div>
                            {message.links.map((link, index) => (
                                <a
                                    key={index}
                                    href={link[1]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginRight: 10, textDecoration: 'none', color: 'white' }}
                                >
                                    <button className="style2">
                                        {link[0]}
                                    </button>
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default WombatLogin;
