import * as React from "react"
import { SVGProps } from "react"
const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 224.22 28.391"
    width={props.width ? props.width : 224.22}
    height={props.height ? props.height : 28.391}
    {...props}
  >
    <g data-name="Grupo 163">
      <path
        fill="#fff"
        d="M82.977 6.319v4.8H100.5l-.552 6.238H82.974v4.8h18.673v6.238H75.89V.099h24.61v6.238Z"
        data-name="Trazado 124-2"
      />
      <path
        fill="#fff"
        d="M142.684 28.391h-7.074V6.323h-10.92V.085h28.858v6.238h-10.861Z"
        data-name="Trazado 125-2"
      />
      <g data-name="Grupo 4">
        <path
          fill="#dc3838"
          d="m115.109 24.689-.948-2.232a8.332 8.332 0 0 1-7.321-7.321l-2.228-.944 2.235-.941a8.332 8.332 0 0 1 7.321-7.321l.944-2.235.941 2.235a8.332 8.332 0 0 1 7.3 7.331l2.235.941-2.235.93a8.332 8.332 0 0 1-7.321 7.321l-.941 2.235Zm0-6.242.941 2.122a6.443 6.443 0 0 0 5.421-5.421l-2.122-.941 2.122-.941a6.443 6.443 0 0 0-5.421-5.421l-.941 2.122-.944-2.122a6.443 6.443 0 0 0-5.421 5.418l2.122.941-2.122.941a6.444 6.444 0 0 0 5.418 5.411Zm-.948-4.244a.948.948 0 0 1 .944-.948.951.951 0 0 1 .948.948.948.948 0 1 1-1.892.116.9.9 0 0 1 0-.116Z"
          data-name="Uni\xF3n 9-2"
        />
      </g>
      <g fill="#fff" data-name="Grupo 159">
        <path
          d="M53.846 7.398 44.899.039h-7.073v28.29h7.073V8.272l14.146 11.713v8.36h7.073V17.506L53.846 7.409Z"
          data-name="Trazado 126-2"
        />
        <path
          d="M66.118.039h-7.073v7.936l7.073 5.832Z"
          data-name="Trazado 127"
        />
      </g>
      <g data-name="Grupo 160">
        <path
          fill="#fff"
          d="M173.893 0h-5.941l-14.3 28.387h8.191l2.589-5.6h.032l6.461-14.217 3.735 8.275h-4.361l-2.7 5.941h9.775l2.589 5.6h8.191Z"
          data-name="Trazado 128-2"
        />
      </g>
      <g data-name="Grupo 161">
        <path
          fill="#fff"
          d="M222.226 2.122C220.783.679 218.874.085 215.733.085h-19.8v28.292h7.073V6.309h11.6c1.995 0 2.546.467 2.546 2.122v3.1c0 1.655-.552 2.122-2.546 2.122h-8.824v6.238h9.959c3.14 0 5.05-.594 6.493-2.037s1.995-3.268 1.995-6.451v-2.83c-.008-3.183-.56-5.008-2.003-6.451Z"
          data-name="Trazado 129-2"
        />
      </g>
      <g data-name="Grupo 162" transform="translate(0 .085)">
        <path
          fill="#fff"
          d="M26.294 2.037C24.851.594 22.945 0 19.8 0H8.488c-3.14 0-5.05.594-6.493 2.037S0 5.305 0 8.53v11.246c0 3.225.552 5.05 1.995 6.493s3.353 2.037 6.493 2.037H19.8c3.14 0 5.047-.594 6.489-2.037s1.995-3.268 1.995-6.493V8.53c.004-3.225-.547-5.05-1.99-6.493ZM21.2 19.946c0 1.655-.552 2.122-2.546 2.122h-1.672l-2.829 3.892-2.829-3.89h-1.7c-1.995 0-2.546-.467-2.546-2.122V8.36c0-1.655.552-2.122 2.546-2.122h9.036c2.037 0 2.546.424 2.546 2.122Z"
          data-name="Trazado 130-2"
        />
        <image
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAqCAYAAAAj6gIfAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAL6ADAAQAAAABAAAAKgAAAAADfGu9AAAEOUlEQVRYCe2Yi67TMAxA73jz/x/Lm+GT9RTXS/oaAk3CUubEsZ1jN+1FXK7X68uzyqtnBYf7zaPwl5CzOeKpP/TYL2fiV4D3FNIFPlPIIfgCXUHX1hV4db23kF3wA2hhq+YWaas3Sugt/bKngE34BC4Qus6rrRbQg8WW7XXdCl8rYvWFXQHnKyUwuq452AKZ9yCx/Zz2nOOrGOP6Tg/hC3gGFRZgR7bpWw8DRkg18A7iLMbYKxyj7g/hjU46Awr9OvYZrNXMawEZXNgf4ccc7TymzYZWhgV04TtdFyhDEwuw2kIsggKU3Glh0d9jmJu1Up+A9oXuwk8edk9dwd+GH/Fq5rkQ43LXBQZacHQVYmrx1ef+L2zqus5CCC8k0Ix303BtAXY0dx3Qb9P4GlqfmDax0KzbRu/uc9CaCI7mIK8GcYK/j7mDQiwOX+KEp+uAA13B9fG6oD07pvPXivksa/AEKyaq3QcW8A/TYI6Nwio8XQeaM92LaQOjMIdnqSmMOXohI3icFZOge90X/mPsUwRr4fHnULvOediEwU5R2NE2xzPDNJYRvBEkQUyGtgBiGfn6CO/1qfB0HOFaAEssVwn7IfDwb4ejj0guhEMZtQi6j014u1qfgtA5Z4TtEw44Kx5ox9AWQt4Mjy/g2CwYf3Oow7RfSNATX46q8dVmnGsAEEFyUdpuHve/5HDc7w4sa50nGYcKZ3I0d5bBC1fn2RbbLV6fkc6585z4oazB5yATCuxXQs1Lx71GezWI8cm6x74DG4McDosjFvFc1zfr9HsHz7/g0l/ZHGziXIAgfL+/xPAFJD1fEq8SkPqgHRaANn8+M8xjuYNPriTx2pCYLgrOYXZZaMCFpZPkrp3/HLZPMYixAPL4BC0AbRExjUVIm6SfNfgWM/kSKDhAHMah9csRpuZnYfgSqz/QFMCwAHxpBj6MDL4oIPYW0oUvV4cA4eksB3AYYHTP7sa0HcwednLnJ6E9F4Af8AzBM3yY+13H3oVnYxKgFQvgEKDysEMWRl7vv3HAA+l1yZ0nTnhzqWOrL0P41H2SICbz+gjvnnYAycsT8dq4ZwEWgWZgr/BhGnedvSG8geXLA0QVisIuYO46BVq03QXUIpwLbh5jwnUsq/ApjGSKBQDG4R6EnbUv8ejJAIqfxaiJN1c7r/eFCZ9ZNuE714dgC7AoNDZAvC49eHz0y5q5OWK6TzbhSbOjAIAB6IG3FKSZBn4OgdX6dr/rbGbZ/B+zhXO8ANParmYNeF7rSwhwiB3OhThnv/ltXRcckV2dv7kufoXByBxQtdBqfdTGoh3u7eo4zsihzt9CImj5BFqeaU9gtSHoDN1bHwInwSn4Fvi7gLbkZ5IeuHsWwHqe770mJlGfhp8TLIuYzU6SnmGT7XC3c+zD8CZLV0nTUJ/tdE34x+Br4r+x5vP2tPIf/l89ul9frDZaGTV5zQAAAABJRU5ErkJggg=="
          width={16.621}
          height={14.853}
          data-name="Rect\xE1ngulo 1"
          opacity={0.3}
          style={{
            isolation: "isolate",
          }}
          transform="translate(5.75 7.649)"
        />
        <path
          fill="#dc3838"
          d="M14.139 17.095 17 13.177h-5.722Z"
          data-name="Trazado 131-2"
        />
      </g>
    </g>
  </svg>
)
export default Logo
